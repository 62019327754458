import { Component, ElementRef, OnInit, inject, AfterViewInit, HostListener, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { environment } from '@environment';
import { EventMatchService } from '../common/services/event-match.service';
import { EventService } from '../../admin-portal/events/services/event.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IEvent } from '@model/interfaces/event';
import { forkJoin, interval, Subscription } from 'rxjs';
import { ITeam } from '@model/interfaces/team';
import { IEventMatchList, IEventMatchDisplay } from '@model/interfaces/custom/event-match-display';
import { IEventMatchScreenPagination } from '@model/interfaces/custom/event-match-screen-pagination';
import { provideTranslocoScope } from "@jsverse/transloco";
import { EventMatchStatuses } from '@model/enums/event-match-statuses.enum';

@Component({
    selector: 'app-match-schedule-screen',
    styleUrls: ['./match-schedule-screen.component.less'],
    templateUrl: './match-schedule-screen.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        provideTranslocoScope({
          scope: "event-matches",
          alias: "e",
        }),
    ],
})
export class MatchScheduleScreenComponent implements OnInit, AfterViewInit, OnDestroy  {
    eventName: string;
    screenTitle: string = 'MATCH SCHEDULE' as string;
    programImagePath: string;
    event: IEvent;
    divHeight: number;
    divHeightHeader: number;
    divHeightRow: number;
    eventMatches: IEventMatchDisplay;
    eventMatchLists: IEventMatchList[];
    teams: ITeam[] = [];
    isLoading = false;
    eventId: number;
    currentPage = 1;
    itemsPerPage = 10;
    total: number;
    subscription: Subscription;
    logoFull = `${environment.assetsPath}logo-full.png`;
    canceledMatch: number;

    private _authService = inject(AuthService);
    private _eventService = inject(EventService);
    private _notificationsService = inject(NotificationsService);
    private _eventMatchService = inject(EventMatchService);
    private _el = inject(ElementRef);

    ngOnInit(): void {
        this.eventId = this._authService.currentUser.value.CustomOptions.EventId;
        this.canceledMatch = EventMatchStatuses.Canceled;
        if (this.eventId > 0) {
            const data = this.getSearchParams();
            forkJoin([
                this._eventService.getById(this.eventId),
                this._eventMatchService.getMatchesByEventId(data),
            ])
            .subscribe(([event, eventMatches]) => {
                this.event = event;
                this.eventName = event.Name;
                this.programImagePath = environment.assetsPath + 'images/noimage.png';
                const programId: number = event.Season?.ProgramId ?? 0;
                if (programId > 0) {
                    this.programImagePath = this.getImageUrl(programId);
                }
                this.eventMatches = eventMatches;
                this.eventMatchLists = this.eventMatches?.EventMatchLists;
                this.total = this.eventMatches?.Total;
            });

            this.subscription = interval(10000).subscribe(() => {
                this.loadMatchList();
            })
        } else {
            this._notificationsService.error('Event match schedule not found.');
        }
    }

    getSearchParams(): IEventMatchScreenPagination {
        const skip = (this.currentPage - 1) * this.itemsPerPage;
        const eventPagination: IEventMatchScreenPagination =  {
            EventId: this.eventId,
            Skip: (this.currentPage - 1) * this.itemsPerPage,
            Take: this.itemsPerPage
        }
        const currentRowNumber = skip + this.itemsPerPage;
        if (this.total <= currentRowNumber) {
            this.currentPage = 0;
        }
        return eventPagination;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.setDivHeight();
        }, 0);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    loadMatchList() {
        if (this.total) {
            if (this.total <= this.itemsPerPage) {
                this.currentPage = 0;
            } else {
                this.currentPage++;
            }
            const data = this.getSearchParams();
            this._eventMatchService.getMatchesByEventId(data).subscribe(eventMatches => {
                this.eventMatches = eventMatches;
                this.eventMatchLists = this.eventMatches?.EventMatchLists ;
                this.total = this.eventMatches?.Total;
            });
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.setDivHeight();
    }

    setDivHeight() {
        const windowHeight = window.innerHeight;
        if (windowHeight) {
            const headerHeight = windowHeight * .20;
            this._el.nativeElement.querySelector('.screen-header').style.height = `${headerHeight}px`;
            const divHeight = window.innerHeight - headerHeight;
            const getHeaderHeight = divHeight * .10;
            this.divHeightHeader = getHeaderHeight;
            this.divHeightRow = (divHeight - this.divHeightHeader)/ 10;
            this._el.nativeElement.querySelector('.screen-content').style.height = `${divHeight}px`;
        }
    }

    getImageUrl(programId: number): string {
        return `${environment.baseApiUrl}/api/v1/programs/${programId}/pic`;
    }

    getTeamMatch(eventMatch: IEventMatchList) {
        const [teamOne, teamTwo, teamThree, teamFour] = eventMatch.Teams;
        const extraMatchInfo = eventMatch.MatchTeams ;
        const teamOneSpan = this.createTeamSpan(teamOne , extraMatchInfo, 'red');
        const teamTwoSpan = this.createTeamSpan(teamTwo , extraMatchInfo, 'red');

        if (teamThree && teamFour) {
            const teamThreeSpan = this.createTeamSpan(teamThree , extraMatchInfo, 'blue');
            const teamFourSpan = this.createTeamSpan(teamFour , extraMatchInfo, 'blue');
            return `${teamOneSpan} and ${teamTwoSpan} vs ${teamThreeSpan} and ${teamFourSpan}`;
        } else {
            return `${teamOneSpan} vs ${teamTwoSpan}`;
        }
    }

    getMatchTime(eventMatch: IEventMatchList) {
        const date = new Date(eventMatch.MatchTime );
        return date.toLocaleTimeString();
    }

    private formatTeamName(team: ITeam, hasExtraMatch: boolean): string {
        const baseTeamName = `${team.Letter}${team.Number}`;
        return hasExtraMatch ? `${baseTeamName}*` : baseTeamName;
    }

    private createTeamSpan(team: ITeam, extraMatchInfo: Record<number, boolean>, color: 'red' | 'blue'): string {
        const teamName = this.formatTeamName(team, extraMatchInfo[team.Id]);
        return `<span class="screen-content-row-column-teams-vs--${color}">${teamName}</span>`;
    }
}
