import { Component, inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { EnvironmentService } from '@mt-ng2/environment-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { TournamentAuthService } from '../../auth-entity/tournament-auth.service';
import { LoginConfig } from '@mt-ng2/login-module';

interface ITournamentLoginForm {
    email: FormControl<string | null>;
    eventCode: FormControl<string | null>;
}

@Component({
    selector: 'app-tournament-login',
    templateUrl: './volunteer-login.component.html',
})
export class VolunteerLoginComponent implements OnInit {
    tournamentLoginForm = new FormGroup<ITournamentLoginForm>({
        email: new FormControl('', [Validators.required.bind(this), Validators.email.bind(this)]),
        eventCode: new FormControl('', [Validators.required.bind(this)])
    });

    get emailErrorStatus() {
        return this.getEmailErrorStatus();
    }

    get eventCodeErrorStatus() {
        return this.getEventCodeErrorStatus();
    }

    logoFull: string;

    protected tournamentAuthService: TournamentAuthService = inject(TournamentAuthService);
    protected notificationsService: NotificationsService = inject(NotificationsService);
    protected environmentService: EnvironmentService = inject(EnvironmentService);
    public config: LoginConfig = inject(LoginConfig);

    constructor() {
        this.logoFull = this.config.useAssetsFolderForImages
            ? `${this.environmentService.config.assetsPath}logo-full.png`
            : `${this.environmentService.config.imgPath}logo-full.png`;
    }

    ngOnInit(): void {
        // appReady determines if an authenticated connection has been made.
        // While it's waiting it shows a loading icon.  When appReady has a
        // value the loading icon is hidden.  We always want this to be true
        // when you are on the login page.  Because you aren't authed!
        if (this.tournamentAuthService.appReady && !this.tournamentAuthService.appReady.getValue()) {
            this.tournamentAuthService.appReady.next(true);
        }
    }

    getEmailErrorStatus(): { hasError: boolean; required: boolean; invalid: boolean; otherError: boolean } {
        const control = this.tournamentLoginForm.get('email');
        if (!control) {
            return { hasError: false, required: false, invalid: false, otherError: false };
        }

        const hasError = control.errors !== null && control.touched;
        const required = hasError && control.hasError('required');
        const invalid = hasError && control.hasError('email');
        const otherError = hasError && !required && !invalid;

        return { hasError, required, invalid, otherError };
    }

    getEventCodeErrorStatus(): { hasError: boolean; required: boolean; otherError: boolean } {
        const control = this.tournamentLoginForm.get('eventCode');
        if (!control) {
            return { hasError: false, required: false, otherError: false };
        }

        const hasError = control.errors !== null && control.touched;
        const required = hasError && control.hasError('required');
        const otherError = hasError && !required;

        return { hasError, required, otherError };
    }

    onLogin(): void {
        if (this.tournamentLoginForm.valid) {
            const values = this.tournamentLoginForm.value;
            this.tournamentAuthService.getVolunteerAccessLink(values.email ?? '', values.eventCode).subscribe({
                next: () => {
                    this.notificationsService.success('Access link email sent.');
                },
                error: () => {
                    this.notificationsService.error('Access link email failed to send.');
                },
            });
        } else {
            markAllFormFieldsAsTouched(this.tournamentLoginForm);
        }
    }
}
