import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';
import { VolunteerAccessComponent } from './access/volunteer-access.component';
import { VolunteerLoginComponent } from './volunteer-login/volunteer-login.component';

@NgModule({
    declarations: [VolunteerAccessComponent, VolunteerLoginComponent],
    exports: [VolunteerAccessComponent, VolunteerLoginComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MtDisableDuringHttpCallsModule],
})
export class TournamentLoginModule {}
