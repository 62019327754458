import { Component, ErrorHandler, inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { TournamentAuthService } from '../../auth-entity/tournament-auth.service';
import { appPaths } from 'tournament-portal/default-routes/app-paths.library';

@Component({
    selector: 'app-tournament-access',
    template: '',
})
export class VolunteerAccessComponent implements OnInit {
    authUserId?: number;
    key?: string;
    badKeyError = `Oops something went wrong!
    - Probably the key has been used or expired.
    - Or you did something you weren't supposed to do.
    - Your best bet is to just generate a new email.`;

    protected tournamentAuthService: TournamentAuthService = inject(TournamentAuthService);
    protected router: Router = inject(Router);
    public route: ActivatedRoute = inject(ActivatedRoute);
    protected notificationsService: NotificationsService = inject(NotificationsService);
    protected globalErrorHandler: ErrorHandler = inject(ErrorHandler);

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.authUserId = params['userId'];
            this.key = params['key'];
        });
        if (!this.authUserId || !this.key) {
            void this.router.navigate([`/tournament/${appPaths.home as string}`]);
        }
        this.tournamentAuthService.tournamentAccess(this.authUserId, this.key).subscribe({
            next: () => {
                this.success();
                void this.router.navigate([`/tournament/${appPaths.home as string}`]);
            },
            error: (error) => {
                if (error.status === 418) {
                    this.notificationsService.error('Access link is invalid');
                    console.error(this.badKeyError);
                } else if (error.status === 400) {
                    if (error.error) {
                        if (typeof error.error === 'string') {
                            this.error(error.error as string);
                        } else if (typeof error.error?.ModelState?.Service === 'string') {
                            this.error(error.error.ModelState.Service as string);
                        } else {
                            this.globalErrorHandler.handleError(error);
                        }
                    } else {
                        this.error('Something went wrong, Please generate a new access email');
                        console.error(this.badKeyError);
                    }
                }
            },
        });
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Save Failed');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Welcome!');
    }
}
