<div class="login-box">
    <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
    <div class="login-box-body">
        <p class="login-box-msg">Tournament Login</p>
        <form [formGroup]="tournamentLoginForm" (submit)="onLogin()">
            <div class="form-group has-feedback" [class.has-error]="eventCodeErrorStatus.hasError">
                <input type="text" class="form-control" placeholder="Event Code" formControlName="eventCode" />
                <span class="fa fa-calendar-o form-control-feedback"></span>
                <div *ngIf="eventCodeErrorStatus.required" class="small errortext" [style.position]="'block'">Event Code is required</div>
            </div>
            <div class="form-group has-feedback" [class.has-error]="emailErrorStatus.hasError">
                <input type="text" class="form-control" placeholder="example@domain.com" formControlName="email" />
                <span class="fa fa-user form-control-feedback"></span>
                <div *ngIf="emailErrorStatus.required" class="small errortext" [style.position]="'block'">Email is required</div>
                <div *ngIf="emailErrorStatus.invalid" class="small errortext" [style.position]="'block'">
                    Invalid email address. Valid e-mail can contain only letters, numbers and '@'
                </div>
            </div>
            <div class="padded block-parent">
                <button [disabled]="!tournamentLoginForm.valid" type="submit" class="btn btn-primary btn-flat inline-block block-right">Login</button>
            </div>
        </form>
    </div>
</div>
