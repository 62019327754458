export const appPathSlugs = {};

export const appPaths = {
    adminAccess: 'adminaccess',
    forgotPassword: 'forgotpassword',
    login: 'login',
    resetPassword: 'resetpassword',
    verifyEmail: 'verifyemail',
    volunteerLogin: 'volunteers/login',
    volunteerAccess: 'volunteers/access',
};
