export enum ClaimTypes {
    Administration = 1,
    Users = 2,
    UserRoles = 3,
    Audit = 4,
    AccessRequests = 5,
    Programs = 6,
    EventRegions = 7,
    Seasons = 8,
    Teams = 9,
    Organizations = 10,
    CanRegisterOutsideRegistrationDates = 11,
    LevelClasses = 12,
    QualificationLevels = 13,
    CanViewOtherTeams = 14,
    Events = 15,
    CanEditTeamOrganizationAndNumber = 16,
    CanSetPrimaryCoach = 17,
    CanCreateEvents = 18,
    CanMoveTeamsToADifferentContact = 19,
    CanViewOtherEvents = 20,
    TournamentPortal = 21,
    CanScoreQualificationMatches = 22,
    CanTimeMatches = 23,
    TeamworkMatchScoring = 24,
    SkillsMatchScoring = 25,
    FieldControl = 26,
    AllianceSelection = 27,
    TeamCheckIn = 28,
    TeamInspection = 29,
    EnteringAwardWinners = 30,
    CanViewBackgroundCheck = 31,
    CanViewExpirationDate = 32,
    EventSkillsChallenges = 33,
    Settings = 34,
}
