import { ErrorHandler, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from '@mt-ng2/cookie';
import { NgxMaskModule } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ToastrModule } from 'ngx-toastr';

import { AuthModule, AuthModuleConfigToken } from '@mt-ng2/auth-module';
import { EnvironmentModule, EnvironmentModuleConfigToken } from '@mt-ng2/environment-module';
import { BreckErrorHandler } from '@mt-ng2/errors-module';
import { MtLoginModule, LoginModuleConfigToken } from '@mt-ng2/login-module';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';

import { AppRoutingModule } from './app-routing.module';
import { AdminPortalSharedModule } from '@admin-portal-common/shared.module';
import { AppComponent } from './app.component';
import { environment } from '@environment';
import { LoginConfigOverride } from '@common/configs/login.config';
import { TranslocoRootModule } from './transloco-root.module';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { TournamentLoginModule } from './tournament-portal/login/tournament-login.module';
import { CommonModule } from '@angular/common';
import { MatchScheduleScreenComponent } from './tournament-portal/match-schedule-screen/match-schedule-screen.component';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, MatchScheduleScreenComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule,
        AdminPortalSharedModule.forRoot(),
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.withOptions({
            secure: true,
            sameSite: 'strict',
        }),
        EnvironmentModule,
        NotificationsModule,
        AuthModule.forRoot(),
        MtDisableDuringHttpCallsModule.forRoot(),
        AppRoutingModule,
        NgxMaskModule.forRoot(),
        MtLoginModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
        TranslocoRootModule,
        TranslocoModule,
        TournamentLoginModule,
        CommonModule,
    ],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LoginModuleConfigToken, useValue: { ...LoginConfigOverride, paths: { homePath: '/portal' } } },
        { provide: EnvironmentModuleConfigToken, useValue: environment },
        { provide: TRANSLOCO_SCOPE, useValue: ['public-pages', 'users'] },
        {
            provide: AuthModuleConfigToken,
            useValue: {
                pathOverrides: {
                    homePath: '/portal/users/my-profile',
                },
            },
        },
    ],
})
export class AppModule {}
