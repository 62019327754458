import { Injectable } from '@angular/core';
import { AuthService, ILoginResponse, mapToken } from '@mt-ng2/auth-module';
import { Observable, tap, mergeMap, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TournamentAuthService extends AuthService {
    tournamentPortalAccessLogin(eventId: number): Observable<ILoginResponse> {
        return this.getToken().pipe(
            mergeMap((token) => {
                const data: ITournamentPortalAccessRequest = {
                    AuthClientID: this.environmentService.config.authClientId,
                    AuthClientSecret: this.environmentService.config.authSecretVariable,
                    AuthUserId: this.currentUser.getValue().AuthId,
                    TokenIdentifier: token.refreshId,
                    EventId: eventId,
                };
                return this.http
                    .post<ILoginResponse>('/tournament-auth/tournament-portal-access', data)
                    .pipe(tap((response) => this.saveToken(response, false)));
            }),
        );
    }

    tournamentAccess(authUserId: number, key: string): Observable<ILoginResponse> {
        const data = {
            AuthClientId: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            AuthUserId: authUserId,
            Key: key,
        };
        return this.http
            .post<ILoginResponse>('/tournament-auth/volunteer-access', data, {
                headers: { BypassAuth: 'true' },
            })
            .pipe(tap((response) => this.saveToken(response, false)));
    }

    getVolunteerAccessLink(email: string, eventCode: string): Observable<string> {
        const data = {
            AuthClientId: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            EventCode: eventCode,
            Email: email,
        };
        return this.http.post<string>('/tournament-auth/volunteer-link', data);
    }

    volunteerRefresh(): void {
        this.getLoginTokenFromCookie().pipe(
            mergeMap((currentToken) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const data = {
                    AuthClientID: this.environmentService.config.authClientId,
                    AuthClientSecret: this.environmentService.config.authSecretVariable,
                    AuthUserId: currentToken?.authUserId,
                    TokenIdentifier: currentToken?.refreshId,
                    EventTournamentUserId: this.currentUser.getValue().CustomOptions.EventTournamentUserId
                };
                return this.http
                    .post<ILoginResponse>('/tournament-auth/volunteer-refresh', data, {
                        headers: { BypassAuth: 'true' },
                    })
                    .pipe(
                        tap((response) => this.saveToken(response, currentToken?.remember ?? false)),
                        map((response) => mapToken(response, currentToken?.remember ?? false)),
                    );
            })
        );
    }
}

interface ITournamentPortalAccessRequest {
    AuthClientID: number | undefined;
    AuthClientSecret: string | undefined;
    AuthUserId: number;
    TokenIdentifier: string;
    EventId: number;
}
